<template>
  <div class="aiz-user-panel">
    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h1 class="h3">{{ $t('shou-ye') }}</h1>
        </div>
      </div>
    </div>

    <div class="row gutters-10">
      <div class="col-md-4">
        <div class="bg-grad-1 text-white rounded-lg mb-4 overflow-hidden">
          <div class="px-3 pt-3">
            <div class="h3 fw-700">
              <span>{{ $t('ren-wu-shu-liang') }}</span>
              <span>{{taskCommissionData.FinishCount}}/{{taskCommissionData.OrderCount}}</span>
              <br />
              <br />
              <a  class="btn btn-primary" @click="toPath"> {{ $t('Entry task') }} </a>
            </div>
          </div>
          <img :src="bgUrl1" alt="">
        </div>
      </div>
      <div class="col-md-4">
        <div class="bg-grad-2 text-white rounded-lg mb-4 overflow-hidden">
          <div class="px-3 pt-3">
            <div class="h3 fw-700">
              <span>{{ $t('yao-qing-ma') }}：</span>
              <span>{{ userInfo.InvitationCode }}</span>
              <br />
              <br />
              <a  class="btn btn-primary copy-icon" @click="copyData"> {{ $t('fu-zhi-lian-jie') }} </a>
            </div>
          </div>
          <img :src="bgUrl1" alt="">
        </div>
      </div>
      <div class="col-md-4" v-if="false">
        <div class="bg-grad-2 text-white rounded-lg mb-4 overflow-hidden">
          <div class="px-3 pt-3">
            <div class="h3 fw-700">{{wishList.length}}{{ $t('chan-pin') }}</div>
            <div class="opacity-50">{{ $t('zai-nin-de-yuan-wang-qing-dan-zhong') }}</div>
          </div>
          <img :src="bgUrl2" alt="">
        </div>
      </div>
      <div class="col-md-4">
        <div class="bg-grad-3 text-white rounded-lg mb-4 overflow-hidden">
          <div class="px-3 pt-3">
              <div class="h3 fw-700">{{orderCount}}{{ $t('chan-pin') }}</div>
              <div class="opacity-50">{{ $t('ni-dian-le') }}</div>
          </div>
          <img :src="bgUrl3" alt="">
        </div>
      </div>
    </div>


    <div class="row gutters-10">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
              <h6 class="mb-0">{{ $t('mo-ren-song-huo-di-zhi') }}</h6>
          </div>
          <div class="card-body">
            <ul class="list-unstyled mb-0" v-if="addressInfo.ID">
              <li class=" py-2"><span>{{ $t('di-zhi') }}{{addressInfo.Address}}</span></li>
              <li class=" py-2"><span>{{ $t('guo-jia') }}{{addressInfo.Country}}</span></li>
              <li class=" py-2"><span>{{ $t('sheng-fen') }}{{addressInfo.Province}}</span></li>
              <li class=" py-2"><span>{{ $t('cheng-shi') }}{{addressInfo.City}}</span></li>
              <li class=" py-2"><span>{{ $t('you-bian') }}{{addressInfo.ZipCode}}</span></li>
              <li class=" py-2"><span>{{ $t('quan-ming-0') }}{{addressInfo.FullName}}</span></li>
              <li class=" py-2"><span>{{ $t('dian-hua-0') }}{{addressInfo.Phone}}</span></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6" v-show="false">
        <div class="card">
          <div class="card-header">
            <h6 class="mb-0">购买的套餐</h6>
          </div>
          <div class="card-body text-center">
            <img :src="bgUrl1" class="img-fluid mb-4 h-110px">
            <p class="mb-1 text-muted">已上传包裹: 4此</p>
            <p class="text-muted mb-4">剩余上传包裹: 5此</p>
            <h5 class="fw-600 mb-3 text-primary">当前包裹: 3</h5>
            <h5 class="fw-600 mb-3 text-primary">丢失的包裹</h5>
            <a  class="btn btn-success d-inline-block">升级包裹</a>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { getAddressList } from '@/api/index'
import {orderList, taskCommission} from '@/api/user'
import Clipboard from "clipboard";
export default {
  data() {
    return {
      bgUrl1: require('../../assets/imgs/mine-bg1.svg'),
      bgUrl2: require('../../assets/imgs/mine-bg2.svg'),
      bgUrl3: require('../../assets/imgs/mine-bg3.svg'),
      addressInfo: {},
      taskCommissionData: {
        FinishCount: '0',
        OrderCount: '0',
        OrderCommission: 0
      },
      orderCount: 0
    }
  },
  computed: {
    cartList() {
      return this.$store.state.cartList
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    wishList() {
      return this.$store.state.wishList
    },

  },
  mounted() {
    this.init()
  },
  methods: {
    toPath(){
      this.$router.push('/order')
    },
    copyData() {
      let data = `${this.$store.state.app_promotion}/#/register?code=${this.userInfo.InvitationCode}`
      const clipboard = new Clipboard('.copy-icon', {text: () => data});
      this.$toast.fail(this.$t('Copy successful'))
    },
    init() {
      getAddressList().then(res => {
        let list = res.data.Items.filter(v => {
          return v.IsSelected === 1
        })
        if (list.length > 0) {
          this.addressInfo = list[0]
        }
      })
      taskCommission().then(res => {
        this.taskCommissionData = res.data
      })

      orderList().then(res => {
        this.orderCount = res.data.Pagination.totalRecords
      })
    }
  }
}
</script>
<style scoped>
.col-md-4 .pt-3{
  height: 5rem;
}
a.btn {
  position: relative; /* 需要设置为相对定位 */
  z-index: 1; /* 设置一个较大的z-index值，使其在上层 */
  width: 13rem;
  height: 3rem;
  font-size: 20px;
}
</style>